// @flow
"use client";
import * as React from "react";
import { FC, CSSProperties, Suspense } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import logoText from "@/assests/img/logo-text.svg";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import icon1 from "@/assests/img/headerBlock-icon-1.svg";
import icon2 from "@/assests/img/headerBlock-icon-2.svg";
import icon3 from "@/assests/img/headerBlock-icon-3.svg";
import { User } from "@runetid/js-sdk";

type IMainHeaderBlockProps = {
  className?: string
  style?: CSSProperties
  user: User | null
};
export const MainHeaderBlock: FC<IMainHeaderBlockProps> = ({ user }) => {
  return (
    <Container>
      <Row className="headerBlock__row">
        <Col>
          <h1>
            <span>Главные события IT-отрасли</span>
            <Image src={logoText} alt="logo" width={658} height={76} />
          </h1>
        </Col>
        <div className="d-none d-xl-block col-auto col-xl-4">
          {user ? <></> : <Link href={"/auth/login"}>
            <Button className="headerBlock__btn" variant="primary">
              Присоединиться
            </Button>
          </Link>}

        </div>
      </Row>
      <div className="headerBlock__wrap">

        <Suspense fallback={null}>
          <Swiper className="headerBlock__swiper" slidesPerView={"auto"}>
            <SwiperSlide className="headerBlock__slide">
              <div className="headerBlock__img-wrap">
                <Image src={icon1} alt="icon" width={40} height={40} />
              </div>
              <span>
                  Участвуйте в крупнейших <br /> IT-событиях{" "}
                </span>
            </SwiperSlide>
            <SwiperSlide className="headerBlock__slide">
              <div className="headerBlock__img-wrap">
                <Image src={icon2} alt="icon" width={40} height={40} />
              </div>
              <span>Создавайте собственные мероприятия вместе с нами</span>
            </SwiperSlide>
            <SwiperSlide className="headerBlock__slide">
              <div className="headerBlock__img-wrap">
                <Image src={icon3} alt="icon" width={40} height={40} />
              </div>
              <span>Будьте в курсе последних событий и трендов IT-отрасли</span>
            </SwiperSlide>
          </Swiper>
        </Suspense>
        <Button className="headerBlock__footer-btn d-xl-none" variant="primary">
          Присоединиться
        </Button>
      </div>
    </Container>
  );
};
