"use client";

import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";

import "swiper/css";
import { User } from "@runetid/js-sdk";

interface HeaderBlockProps {
  user: User | null;
  bgSrc: any;
  children: React.ReactNode;
  statistic?: boolean;
}

const HeaderBlock: FC<HeaderBlockProps> = ({
                                             user,
                                             bgSrc,
                                             children,
                                             statistic = true,
                                           }) => {
  return (
    <section className="headerBlock">
      {children}
      <div className="headerBlock__benefits">
        <Image className="headerBlock__bg" alt="Bg" src={bgSrc} width={1920}
               height={632} />
        <Container>
          <Row>
            <Col className="ms-auto" md="3" xl="4">
              <div className="headerBlock__benefits-wrap">
                {
                  statistic ? <>
                    <div className="headerBlock__benefit">
                      <span>2 000 000+</span>
                      участников
                    </div>
                    <div className="headerBlock__benefit">
                      <span>9000+</span>
                      мероприятий
                    </div>
                  </> : <></>
                }

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default HeaderBlock;
