'use client';
import React, {Suspense} from 'react';
import { Container } from 'react-bootstrap';

import bg from '../assests/img/sSteps-bg-1.jpg';
import Image from 'next/image';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';

const Steps = () => {
  return (
    <section className="sSteps" id='acreditation'>
      <Image className="sSteps__bg" src={bg} alt="bg" />
      <Container className="sSteps__container">
        <h2>
          3 шага для
          <br />
          аккредитации
        </h2>

        <Suspense fallback={null}>
          <Swiper className="sSteps__slider" slidesPerView={'auto'}>
            <SwiperSlide className="sSteps__slide">
              <div className="h6">Вы приходите на мероприятие</div>
              <span>01</span>
            </SwiperSlide>
            <SwiperSlide className="sSteps__slide">
              <div className="h6">Подходите к стойке регистрации</div>
              <span>02</span>
            </SwiperSlide>
            <SwiperSlide className="sSteps__slide">
              <div className="h6">Получаете аккредитацию вашей организации</div>
              <span>03</span>
            </SwiperSlide>
          </Swiper>
        </Suspense>
      </Container>
    </section>
  );
};

export default Steps;
