"use client";
import React, { FC } from "react";
import { Button, Container } from "react-bootstrap";

import bg from "../assests/img/sBeMember-bg-1.png";
import bg2 from "../assests/img/sBeMember-bg-2.svg";
import bg3 from "../assests/img/sBeMember-bg-3.svg";

import Image from "next/image";
import Link from "next/link";
import { User } from "@runetid/js-sdk";

interface BeMemberProps {
  user: User | null;
}

const BeMember: FC<BeMemberProps> = ({ user }) => {
  return (
    <section className="sBeMember">
      <Container className="position-relative">
        <Image
          className="sBeMember__bg d-none d-md-block"
          src={bg}
          alt="bg"
          width={628}
          height={910}
        />
        <div className="sBeMember__wrap">
          <Image className="sBeMember__bg-2" src={bg2} alt="bg" width={292}
                 height={212} />
          <Image className="sBeMember__bg-3" src={bg3} alt="bg" width={1207}
                 height={880} />
          <h2>Станьте участником Runet-ID</h2>
          <div className="sBeMember__content">
            <p>
              Присоединяйтесь к Runet-ID, чтобы участвовать и организовывать
              крупнейшие отраслевые
              мероприятия в РФ
            </p>
            {!user ? <Link href={"/auth/login"}>
              <Button variant="light">Присоединиться</Button>
            </Link> : <Link href={"/user/events/all"}>
              <Button variant="light">В кабинет</Button>
            </Link>}

          </div>
          <Image className="sBeMember__bg d-md-none" src={bg} alt="bg"
                 width={628} height={910} />
        </div>
      </Container>
    </section>
  );
};

export default BeMember;
