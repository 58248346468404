"use client";
import React, { Suspense, useMemo, useState } from "react";

import moment from "moment/moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "moment/locale/ru";
import Image from "next/image";
import { RunetEvent } from "@runetid/js-sdk";


type EventCardProps = {
  event: RunetEvent;
  index?: number
  isEvents?: boolean
};

const EventCard = ({ event, index = 0, isEvents }: EventCardProps) => {
  const hasSlash = useMemo(() => event.logo_source.includes("/"), [event.logo_source]);
  const [src, setSrc] = useState(hasSlash ? event.logo_source : `/${event.logo_source}`);
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {event.title}
    </Tooltip>
  );
  const isMainBlock = useMemo(() => index % 4 === 0, [index]);

  const placeholderImage = useMemo(() => `/img/blank.jpg`, []);

  const onError = () => {
    setSrc(placeholderImage);
  };

  return (
    <a href={`/event/${event.id_name}`}
       className={`event-card ${isEvents ? "isEvents" : ""}`}>
      <div className="event-card__img-wrap">
        <div className="event-card__img-wrap_mask"></div>
        {src ? (
          <Image src={`${src}`} width={isMainBlock ? 994 : 467}
                 height={isMainBlock ? 450 : 265} alt={event.title}
                 onError={onError} />
        ) : (
          ""
        )}1
        <Suspense fallback={null}>
          {
            event.start_time_text ?
              <div
                className="event-card__date">{event.start_time_text}{event?.end_time_text && ` - ${event.end_time_text}`}</div>
              :
              <EventDatesBlock start={event.start_time} end={event.end_time} />
          }
        </Suspense>
      </div>
      <div className="event-card__content">
        <Suspense fallback={null}>
          <EventDatesBlock start={event.start_time} end={event.end_time} />
        </Suspense>
        <OverlayTrigger
          placement="auto"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <div
            className={`h4 ${index % 4 !== 0 ? "cut_h4" : ""}`}>{event.title}</div>
        </OverlayTrigger>

        {event.places && event.places.length && event.places[0].title ? (
          <div className="event-card__info">
            <span>Адрес: </span>
            {event.places[0].title}
          </div>
        ) : (
          ""
        )}
        <Suspense fallback={null}>
          <RegEndBlock end={event.end_time} />
        </Suspense>
      </div>
    </a>
  );
};

export default EventCard;

export type EventDatesBlockProps = {
  start: string;
  end: string;
};

const EventDatesBlock = ({ start, end }: EventDatesBlockProps) => {
  moment.locale("ru");
  const startTime = moment(start).locale("ru");
  const endTime = moment(end).locale("ru");
  const diff = endTime.diff(startTime, "days");

  if (diff < 1) {
    return <div className="event-card__date">{startTime.format("D MMMM")}</div>;
  }

  return (
    <div className="event-card__date">
      {startTime.format("D")} - {endTime.locale("ru").format("D MMMM")}
    </div>
  );
};

type RegEndBlockProps = {
  end: string;
};

const RegEndBlock = ({ end }: RegEndBlockProps) => {
  const endTime = moment(end);
  const now = moment();
  const diff = endTime.diff(now, "days");

  if (diff <= 0) {
    return "";
  }

  return (
    <div className="event-card__info">
      <span>До конца регистрации:</span>
      {diff > 0 ? diff + "д. " : ""}
    </div>
  );
};
